<template>
  <v-dialog
      v-model="dialogStatus"
      persistent
      max-width="1024"
      :id="dialogId"
      :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <!-- BOTTONE APERTURA FORM CREAZIONE UTENTE -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          :disabled="isLoading"
      >
        {{ title }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <divider></divider>
        <slot></slot>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <!-- BOTTONI SALVA E ANNULLA -->
        <dialog-buttons
            :valid="valid"
            :is-loading="isLoading"
            @save="$emit('save', $event)"
            @close="$emit('close', $event)"
        ></dialog-buttons>

      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

import DialogButtons from "@/components/form/DialogButtons";

export default {
  name: "DialogForm",

  components: {
    DialogButtons
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    dialogId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    valid: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dialogStatus: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit('update:dialog', value)
      }
    }
  }

}
</script>

<style scoped>

</style>
