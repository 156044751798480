<template>
    <v-sheet class="pa-5 elevation-2">
        <slot></slot>
    </v-sheet>
</template>

<script>
    export default {
        name: "ListingTemplate"
    }
</script>

<style scoped>

</style>
