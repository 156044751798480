<style scoped>
.action-link {
  cursor: pointer;
}
</style>

<template>
  <page-template>
    <v-data-table
        :headers="headers"
        :items="clients"
        :items-per-page="5"
        :loading="loading"
        class="elevation-1"
    >
      <template v-slot:item.created_at="{ item }">
        {{ $parseDate(item.created_at) }}
      </template>

    </v-data-table>
  </page-template>
</template>

<script>

import apiClient from "@/plugins/apiClient";
import PageTemplate from "@/views/Layout/_partials/PageTemplate";
import {EventBus} from "@/event-bus";

export default {
  /*
   * The component's data.
   */
  data() {
    return {
      loading: false,
      clients: [],
      headers: [
        {text: this.$t('oauth.client_credentials.fields.client_name'), value: 'name', align: 'start', sortable: false},
        {text: this.$t('oauth.client_credentials.fields.client_id'), value: 'id', align: 'start', sortable: false},
        {text: this.$t('oauth.client_credentials.fields.client_secret'), value: 'secret', align: 'start', sortable: false},
        {text: this.$t('oauth.client_credentials.fields.created_at'), value: 'created_at', align: 'start', sortable: false},
      ]
    }
  },

  components: {
    PageTemplate
  },

  /**
   * Prepare the component (Vue 2.x).
   */
  mounted() {
    let self = this
    this.getClients();

    EventBus.$on('reload-oauth-clients', () => {
      self.getClients()
    })
  },

  methods: {
    /**
     * Get all of the OAuth clients for the user.
     */
    getClients() {
      this.loading = true
      apiClient.get('/oauth')
          .then(response => {
            this.loading = false
            this.clients = response.data;
          });
    },
  }
}
</script>
