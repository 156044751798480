<template>
  <div>
    <client-credential-form></client-credential-form>
    <divider></divider>
    <clients></clients>
  </div>
</template>

<script>
import Clients from "@/components/passport/Clients";
import ClientCredentialForm from "@/components/passport/ClientCredentialForm";

export default {


name: "Client",
   components: {
      Clients,
     ClientCredentialForm
   }
}
</script>

<style scoped>

</style>