<template>
  <dialog-form :dialog.sync="dialog"
               dialog-id="client-credential-dialog"
               :title="$t('oauth.client_credentials.create.title')"
               :valid="valid"
               :is-loading="loading"
               @save="save"
               @close="close"
  >
    <v-form
        v-if="showForm"
        ref="form"
        v-model="valid"
        lazy-validation
    >

      <v-row>
        <v-col cols="6">
          <div>
            <input-field
                v-model="client_name"
                :rules="requiredRules"
                :label="$t('oauth.client_credentials.fields.client_name')"
                :required="true"
            ></input-field>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </dialog-form>
</template>

<script>
import DialogForm from "@/views/Layout/_partials/forms/DialogForm";
import {validationRules} from "@/mixins/validationRules";
import apiClient from "@/plugins/apiClient";
import i18n from "@/plugins/i18n";
import {EventBus} from "@/event-bus";

export default {
  name: "ClientCredentialForm",

  components: {
    DialogForm
  },

  mixins: [validationRules],

  data() {
    return {
      showForm: false,
      client_name: '',
      dialog: false,
      valid: false,
      loading: false,
      isEditing: false
    }
  },

  mounted() {
    let self = this
    this.showForm = true
  },

  methods: {
    async save() {

      const valid = await this.$refs.form.validate()

      if (valid) {

        this.loading = true

        if (this.isEditing) {
          console.log('ok')
        } else {
          await apiClient.post('/oauth', {
            name: this.client_name
          })
              .then(() => {
                this.close()
                this.$store.dispatch('snackbar/showSnackBar', {message: i18n.t('oauth.client_credentials.create.messages.success'), type: 'success'})
                EventBus.$emit('reload-oauth-clients');
              })
              .catch(err => {
                this.loading = false
                this.$store.dispatch('snackbar/showSnackBar', {message: i18n.t('oauth.client_credentials.create.messages.error'), type: 'success'})
                console.log('create error err ', err)
              })
        }
      }
    },

    close() {
      console.log('close')
      this.dialog = false
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>